<template>
	<div id="adjustClass">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">调课申请</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				<!-- <el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select> -->
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="content u-f">
				<div class="content-left">
					<div class="u-f-item meun-item" :class="meunLeftIndex == 0 ? 'meun-active' : ''" @click="meunLeftTap(0)">
						<div class="iconfont icon-fabu"></div>
						<div>我的申请</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 1 ? 'meun-active' : ''" @click="meunLeftTap(1)">
						<div class="iconfont icon-caogao"></div>
						<div>抄送给我</div>
					</div>
					<div class="font_blod font_16 p-t-b">审核</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 2 ? 'meun-active' : ''" @click="meunLeftTap(2)">
						<div class="iconfont icon-yishenhe"></div>
						<div>未审核</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>已审核</div>
					</div>
				</div>
				<!-- 我的申请 -->
				<div class="content-right" v-if="meunLeftIndex == 0">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
						</div>
						<div class="u-f-item">
							<el-button size="small" @click="activityVisible = true">调课申请</el-button>
						</div>
					</div>
					<el-table
						:data="Mylist"
						height="90%"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column type="selection" width="50"></el-table-column>
						<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
						<el-table-column prop="plan" label="教学计划" align="center"></el-table-column>
						<el-table-column prop="media_img" label="功能室登记表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.media_img">功能室登记表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="attendance_img" label="考勤表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.attendance_img">考勤表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="activity_img" label="活动记录表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.activity_img">活动记录表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="give_img" label="授课照片" align="center">
							<template slot-scope="scope">
								<el-image 
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.give_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="success_img" label="成功照片" align="center">
							<template slot-scope="scope">
								<el-image
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.success_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="main_department_name" label="审批部门" align="center"></el-table-column>
						<el-table-column prop="audit" label="审批人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item, index) in scope.row.audit">{{ item.audit_username }}<span v-if="index+1 < scope.row.audit.length">、</span></span>
							</template>
						</el-table-column>
						<el-table-column prop="ectype_department_name" label="抄送部门" align="center"></el-table-column>
						<el-table-column prop="ectype" label="抄送人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item, index) in scope.row.ectype">{{ item.audit_username }}<span v-if="index+1 < scope.row.ectype.length">、</span></span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item"></div>
						<!-- <el-button type="danger" plain size="mini" @click="delActivit">删除</el-button> -->
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 我的抄送 -->
				<div class="content-right" v-if="meunLeftIndex != 0">
					<div class="title u-f-item">
						<div>
							共{{ total }}条数据
						</div>
					</div>
					<el-table
						:data="copyToList"
						height="90%"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column type="selection" width="50"></el-table-column>
						<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
						<el-table-column prop="plan" label="教学计划" align="center"></el-table-column>
						<el-table-column prop="media_img" label="功能室登记表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.media_img">功能室登记表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="attendance_img" label="考勤表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.attendance_img">考勤表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="activity_img" label="活动记录表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.activity_img">活动记录表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="give_img" label="授课照片" align="center">
							<template slot-scope="scope">
								<el-image 
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.give_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="success_img" label="成功照片" align="center">
							<template slot-scope="scope">
								<el-image
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.success_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="main_department_name" label="审批部门" align="center"></el-table-column>
						<el-table-column prop="audit" label="审批人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item, index) in scope.row.audit">{{ item.audit_username }}<span v-if="index+1 < scope.row.audit.length">、</span></span>
							</template>
						</el-table-column>
						<el-table-column prop="ectype_department_name" label="抄送部门" align="center"></el-table-column>
						<el-table-column prop="ectype" label="抄送人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item, index) in scope.row.ectype">{{ item.audit_username }}<span v-if="index+1 < scope.row.ectype.length">、</span></span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item"></div>
						<!-- <el-button type="danger" plain size="mini" @click="delActivit">删除</el-button> -->
						<el-pagination
							@size-change="handleSizeChange1"
							@current-change="handleCurrentChange1"
							:current-page.sync="page1"
							:page-size="limit1"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>

			<el-dialog title="调课申请" :visible.sync="activityVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							调课开始时间
						</div>
						<el-date-picker v-model="start_time" type="datetime" placeholder="选择调课开始时间"></el-date-picker>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							调课结束时间
						</div>
						<el-date-picker v-model="end_time" type="datetime" placeholder="调课结束时间"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							调课表
						</div>
						<el-select v-model="teacher_id" placeholder="请选择" style="width: 70%;" @change="teacherChanage">
							<el-option v-for="item in teacherList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							调课老师
						</div>
						<el-select v-model="teacher_id" placeholder="请选择" style="width: 70%;" @change="teacherChanage">
							<el-option v-for="item in teacherList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							审批部门
						</div>
						<el-select v-model="main_department_id" placeholder="请选择" style="width: 70%;" @change="mainChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							抄送部门
						</div>
						<el-select v-model="ectype_department_id" placeholder="请选择" style="width: 70%;" @change="ectypeChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							审批人
						</div>
						<el-select multiple v-model="audit" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							抄送人
						</div>
						<el-select multiple v-model="ectype" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList2" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeActivity" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit()">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meunLeftIndex: 0,
			total: 0,
			activityVisible: false,
			main_department_id: '',//审核部门id
			ectype_department_id: '',//抄送人部门ID
			audit: [],//审批人
			ectype: [],//抄送人
			sectionList: [],
			peopleList: [],
			peopleList2: [],
			page: 1,
			limit: 10,
			Mylist: [],
			ids: [],
			type: 1,
			copyToList: [],
			condition: '',
			conditionList: [],
			year: '',
			yearList: [],
			semester: '',
			semesterList: [],
			start_time:'',
			end_time:'',
			course_text:'',
			teacher_id:'',
			page1:0,
			limit1:10,
			teacherList:[]
		};
	},
	mounted() {
		this.getYearList();
		this.getSectionList()
	},
	methods: {
		handleSelectionChange() {},
		// 关闭弹窗
		closeActivity() {
			this.reset();
			this.activityVisible = false;
		},
		handleClose(done) {
			this.reset();
			done();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.MyOpenClassList();
		},
		// 分页
		handleSizeChange1(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange1(val) {
			this.page1 = val;
			this.OpenClassList();
		},
		// 选中
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		teacherChanage(e){
			
		},
		// 数据重置
		reset() {
			this.main_department_id = '';
			this.audit = [];
			this.ectype_department_id = '';
			this.ectype = [];
		},
		// 获取部门列表
		getSectionList() {
			this.$api.setting.sectionList({}).then(res => {
				if (res.data.code == 1) {
					this.sectionList = res.data.data.rows;
				}
			});
		},
		meunLeftTap(index) {
			this.meunLeftIndex = index;
			if (index == 1) {
				this.getLeaveMyProcess(2);
			} else if (index == 2) {
				this.getLeaveMyProcess(1, 1);
			} else if (index == 3) {
				this.getLeaveMyProcess(1, 2);
			}
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.year = this.yearList[0].id;
						}
					} else {
						this.year = '';
					}
					this.getSemesterList();
				}
			});
		},
		changeYear(e) {
			this.year = e;
			this.getSemesterList();
		},
		// 学期列表
		getSemesterList() {
			this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
				if (res.data.code == 1) {
					this.semesterList = res.data.data;
					if (res.data.data.length) {
						if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
							this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.semester = this.semesterList[0].id;
						}
					} else {
						this.semester = '';
					}
					this.page = 1;
					this.MyOpenClassList()
				}
			});
		},
		changeSemester(e) {
			this.semester = e;
		},
		mainChanage(e) {
			this.userList(e);
		},
		ectypeChanage(e) {
			this.userList2(e);
		},
		// 人员列表
		userList(id) {
			let data = {
				school_department_id: id
			};
			this.$api.setting
				.userList({
					filter: JSON.stringify(data)
				})
				.then(res => {
					if (res.data.code == 1) {
						this.peopleList = res.data.data.rows;
					}
				});
		},
		// 人员列表
		userList2(id) {
			let data = {
				school_department_id: id
			};
			this.$api.setting
				.userList({
					filter: JSON.stringify(data)
				})
				.then(res => {
					if (res.data.code == 1) {
						this.peopleList2 = res.data.data.rows;
					}
				});
		},
		// 添加公开课
		submit() {
			let data = {
				main_department_id:this.main_department_id,
				audit:this.audit,
				ectype_department_id:this.ectype_department_id,	
				ectype:this.ectype
			};
			this.$api.rule.addOpenClass(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.reset()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取我的公开课申请列表
		MyOpenClassList() {
			let data = {
				page:this.page,
				limit:this.limit
			};
			this.$api.rule.MyOpenClassList(data).then(res=>{
				if(res.data.code==1){
					this.Mylist = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		// 删除请假申请
		delActivit() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					
				});
			} else {
				this.$message.error('请选择要删除的数据');
			}
		},
		// 获取抄送、审核列表
		getLeaveMyProcess(type, status) {
			let data = {
				type: type,
				page:this.page1,
				limit:this.limit
			};
			if (status) {
				data.status = status;
			}
			this.$api.rule.OpenClassList(data).then(res=>{
				if(res.data.code==1){
					this.copyToList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#adjustClass {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 80.5vh;
		border-top: 1px solid #eeeeee;
		.content-left {
			width: 14%;
			border-right: 1px dashed #eeeeee;
			height: 75vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 86%;
			height: 75vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 600px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 10px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	a{
		text-decoration:none;
	}
}
</style>
